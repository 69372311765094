import { useContext, createContext, useEffect, useState,React } from 'react';
import {
  GoogleAuthProvider,
  signInWithPopup,
  signInWithRedirect,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  createUserWithEmailAndPassword,
} from 'firebase/auth';
import { auth } from '../firebase';
import { FacebookAuthProvider,signInWithPhoneNumber,RecaptchaVerifier,getAuth } from "firebase/auth";
import { useNavigate } from "react-router-dom";

const AuthContext = createContext();
const appVerifier = window.recaptchaVerifier;

export const AuthContextProvider = ({ children }) => {
  const [user, setUser] = useState({});
  const facebookSignIn=()=>{
    const provider=new FacebookAuthProvider();
    signInWithPopup(auth,provider)
  }
  const googleSignIn = () => {
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider);
    // signInWithRedirect(auth, provider)
  };
  const emailSignUp = (email, password) => {
    createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        console.log(user);
        // ...
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(error);
        // ..
      });
  }
  const deleteAccount=()=>{
   let user= auth.currentUser;
   user.delete().then(()=>console.log("User Deleted")).catch((error)=>console.log(error))
  }
  const logOut = () => {
      signOut(auth)
  }
  const emailSignIn = async(email, password) => {
   await signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
   
        // Signed in
        const user = userCredential.user;
        
        setUser(user);
        console.log(user);
        return true;
        // ...
      })
      .catch((error) => {

        const errorCode = error.code;
        /* If user entered wrong password */
    
          alert('Please check your email and password');
        const errorMessage = error.message;
        alert(errorMessage);
        console.log(error);
        return false;
      });
  }
  const handlelogin=(user)=>{
    
  }
  const phoneSignIn=(phoneNumber)=>{
    console.log('Verifying')
    window.recaptchaVerifier = new RecaptchaVerifier('recaptcha-container', {
      'size': 'invisible',
      'callback': (response) => {
        console.log(response)
        signInWithPhoneNumber(auth, phoneNumber, appVerifier)
        .then((confirmationResult) => {
          // SMS sent. Prompt user to type the code from the message, then sign the
          // user in with confirmationResult.confirm(code).
          console.log(confirmationResult)
          console.log('sms sent')
          window.confirmationResult = confirmationResult;
          // ...
        }).catch((error) => {
          console.log('Error')
          // Error; SMS not sent
          // ...
          console.log(error)
        });
        // reCAPTCHA solved, allow signInWithPhoneNumber.
        // ...
      },
      'expired-callback': () => {
        // Response expired. Ask user to solve reCAPTCHA again.
        // ...
        console.log('expired callback')
      }
    }, auth);

  }
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      console.log('User', currentUser)
    });
    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <AuthContext.Provider value={{facebookSignIn,phoneSignIn, googleSignIn, emailSignUp,emailSignIn, logOut, deleteAccount,user }}>
      {children}
    </AuthContext.Provider>                                     
  );
};

export const UserAuth = () => {
  return useContext(AuthContext);
};
