import AccountBox from '../components/accountBox';
import styled from "styled-components";

const AppContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: absolute;
  align-items: center;
  justify-content: center;
  background-image: url("bg.jpeg");
`;
function LoginPage(){

    return <AppContainer>
    
    <AccountBox />;</AppContainer> 
}

export default LoginPage;